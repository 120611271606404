<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="60%"
  >
    <div class="handle-content">
      <div class="left-data">
        <div class="data-content">
          <div class="data-title">设备编号</div>
          <div class="data-text" v-if="organId === '99'">{{pageData.pfsn || '-'}}</div>
          <div class="data-text" v-else>{{pageData.sn || '-'}}</div>
        </div>
        <div class="data-content">
          <div class="data-title">状态来源</div>
          <div class="data-text">{{pageData.faultSource || '-'}}</div>
        </div>
        <div class="data-content">
          <div class="data-title">异常类型</div>
          <div class="data-text">{{pageData.faultType || '-'}}</div>
        </div>
        <div class="data-content">
          <div class="data-title">当前运维状态</div>
          <div class="data-text">{{pageData.currentStatus || '-'}}</div>
        </div>
      </div>
      <div class="right-data">
        <el-timeline>
          <el-timeline-item v-for="(item, index) in pageData.faultLineList" :key="index" :timestamp="operationToStr(item.operation)+item.operateTime+ '      '+(item.operator || '')+ '      '+(item.operateInterval||'')" placement="top">
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>

    <template slot="footer">
      <!-- <el-button type="primary" @click="dataFormSubmitHandle()">确认</el-button> -->
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from "lodash/debounce";
import "./Info-detail.less";
import axios from "@/utils/axios";
export default {
  data() {
    return {
      /**是否显示弹窗 */
      visible: false,
      title: null,
      organId: null,
      pageData: {},
      dataForm: {
        id: null,
        sn: null,
      },
    };
  },
  computed: {
    dataRule() {
      return {
        ip0: [{ required: true, message: "IP地址不能为空", trigger: "bulr" }],
        port0: [{ required: true, message: "端口不能为空", trigger: "bulr" }],
      };
    },
  },
  methods: {
    init() {
      this.visible = true;
      this.organId = localStorage.getItem("organId");
      this.$nextTick(() => {
        this.getDataInfo();
        // this.$refs["dataForm"].resetFields();
      });
    },
    //获取数据
    getDataInfo() {
      axios
        .post(`/api/cloudControlFaultLog/faultDetailInfo`, this.dataForm)
        .then((res) => {
          this.pageData = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    operationToStr(value) {
      if(value === 0) {
        return '云控时间：'
      } else if(value === 1) {
        return '回调时间：'
      } else if(value === 2) {
        return '售后时间：'
      } else if(value === 3) {
        return '故障上传时间：'
      } else if(value === 4) {
        return '升级时间：'
      } else if(value === 99) {
        return '完结时间：'
      }
    }
  },
};
</script>
