<template>
  <div class="status-management-container">
    <div class="status-list-tools">
      <el-form class="battery-form" :inline="true" :model="formData">
        <el-form-item label="设备编号">
          <el-input
            v-model="formData.sn"
            placeholder="设备编号"
            size="small"
            class="area-form-item"
            style="width: 180px"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="当前状态">
          <el-select
            v-model="formData.status"
            placeholder="当前状态"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="未完结" :value="2"></el-option>
            <el-option label="已完结" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运维状态">
          <el-select
            v-model="formData.ompStatus"
            placeholder="运维状态"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="待处理" :value="1"></el-option>
            <el-option label="待回调" :value="2"></el-option>
            <el-option label="待售后" :value="3"></el-option>
            <el-option label="已完结" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发生时间:">
          <el-date-picker
            v-model="formData.times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="完结时间:">
          <el-date-picker
            v-model="formData.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange1"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="信息类别">
          <el-select
            v-model="formData.sourceType"
            placeholder="信息类别"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="云控" :value="1"></el-option>
            <el-option label="客服" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="信息类型">
          <el-select
            v-model="formData.faultType"
            placeholder="信息类型"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="故障" :value="1"></el-option>
            <el-option label="低电量" :value="2"></el-option>
            <el-option label="离线" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float: right; marginright: 0px">
          <el-button type="primary" @click="getData()">搜索</el-button>
          <el-button @click="resetFrom()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="status-table-container">
      <div class="status-table-data-info">
        <img class="tips-icon" :src="require('@/assets/img/volume.png')" />
        <span>设备总量</span
        ><span style="margin: 0 10px; color: #ff0000">{{
          statData.total || 0
        }}</span>
        <span>待运维设备</span
        ><span style="margin: 0 10px; color: #ff0000">{{
          statData.wait_omp || 0
        }}</span>
        <span>异常未处理</span
        ><span style="margin: 0 10px; color: #ff0000">{{
          statData.wait_pro || 0
        }}</span>
        <span>售后未处理</span
        ><span style="margin: 0 10px; color: #ff0000">{{
          statData.wait_back || 0
        }}</span>
      </div>
      <div class="status-table-content">
        <el-table
          style="width: 100%"
          height="100%"
          :data="list"
          v-loading="loading"
        >
          <el-table-column label="发生时间" prop="createTime">
            <template slot-scope="scope">
              {{ scope.row.faultTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="异常来源" prop="producer">
            <template slot-scope="scope">
              {{ scope.row.sourceType | actionStr }}
            </template>
          </el-table-column>
          <el-table-column label="完结时间" prop="model">
            <template slot-scope="scope">
              {{ scope.row.overTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="持续时间" prop="interval">
            <template slot-scope="scope">
              {{ scope.row.interval || "-" }}
            </template>
          </el-table-column>
          <el-table-column v-if="formData.organId === '99'" label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              {{ scope.row.pfsn || "-" }}
            </template>
          </el-table-column>
          <el-table-column v-else label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              {{ scope.row.sn || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="故障编码" prop="ompStatus">
            <template slot-scope="scope">
              {{ scope.row.faultCode || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            label="当前状态"
            prop="status"
            :formatter="statusStr"
          >
          </el-table-column>
          <el-table-column
            label="运维状态"
            prop="ompStatus"
            :formatter="ompStatusStr"
          >
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button @click="infoHandle(scope.row)" type="text"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="status-table-page">
        <div class="status-table-page-container">
          <el-pagination
            background
            :current-page="formData.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <InfiModel v-if="visible" ref="infoModel"></InfiModel>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import moment from "moment";
import InfiModel from "./components/Info-detail.vue";
import "./Index.less";
export default {
  name: "StatusManagement",
  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      visible: false,
      statData: {},
      formData: {
        sn: null,
        status: null,
        faultTimeStart: null,
        faultTimeEnd: null,
        endTime: null,
        startTime: null,
        model: null,
        ompStatus: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        organId: null,
        times: [],
        time: [],
        sourceType: null,
        faultType: null
      },
    };
  },
  components: { InfiModel },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    this.getData();
    this.getDataState();
  },
  methods: {
    //获取列表数据
    getData() {
      this.loading = true;
      axios
        .post(`/api/cloudControlFaultLog/faultLogList`, this.formData)
        .then((res) => {
          const { list, total, page } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    //重置
    resetFrom() {
      this.formData = {
        sn: null,
        status: null,
        faultTimeStart: null,
        faultTimeEnd: null,
        finishTimeEnd: null,
        finishTimeStart: null,
        model: null,
        ompStatus: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        organId: localStorage.getItem("organId"),
        times: [],
        time: [],
        sourceType: null,
        faultType: null
      };
      this.getData();
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.formData.faultTimeStart = this.DateToStr(data[0]);
        this.formData.faultTimeEnd = this.DateToStr(data[1]);
      } else {
        this.formData.faultTimeStart = null;
        this.formData.faultTimeEnd = null;
      }
    },
    //时间选择
    timeChange1(data) {
      if (data) {
        this.formData.finishTimeStart = this.DateToStr(data[0]);
        this.formData.finishTimeEnd = this.DateToStr(data[1]);
      } else {
        this.formData.finishTimeStart = null;
        this.formData.finishTimeEnd = null;
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    
    //获取统计项
    getDataState() {
      axios
        .post(`/api/device/count`, {organId: this.formData.organId})
        .then((res) => {
          this.statData = res;
        })
        .catch((err) => {
          this.$message.error(meessag);
        });
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },
    //跳转详情
    infoHandle(row) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.infoModel.init()
        this.$refs.infoModel.title = '异常状态详情';
        this.$refs.infoModel.dataForm.sn = row.sn;
        this.$refs.infoModel.dataForm.id = row.id;
      })  
    },
    //当前状态字典
    statusStr(item) {
      if (item.status === 1) {
        return "已完结";
      } else if (item.status === 2) {
        return "未完结";
      } else {
        return "-";
      }
    },
    //运维状态字典
    ompStatusStr(item) {
      if (item.ompStatus === 1) {
        return "待处理";
      } else if (item.ompStatus === 2) {
        return "待回调";
      } else if (item.ompStatus === 3) {
        return "待售后";
      } else if (item.ompStatus === 4) {
        return "已完结";
      } else if (item.ompStatus === 0) {
        return "正常";
      } else {
        return "-";
      }
    },
  },
  filters: {
    actionStr(value) {
      if (value === 1) {
        return "云控";
      } else if (value === 2) {
        return "客服";
      } else {
        return "-";
      }
    },
  },
};
</script>